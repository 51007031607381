import React from "react"

export default () => {
  return (
    <svg
      viewBox="0 0 860 472"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      width="100%"
    >
      <path d="M57.26 4.76C44.34 7.29 28.36 15.94 18.51 25.79C13.72 30.59 8.39 37.25 6.66 40.57C-0.13 54.02 0 47.37 0 237.91C0 408.88 0.13 416.21 2.53 423.93C9.32 445.64 29.69 463.48 55.26 470C68.71 473.46 68.18 473.6 74.3 458.95C82.29 439.64 255.53 15.67 258.45 8.22C258.62 7.8 259.47 5.71 260.98 1.96C203.62 2.04 171.75 2.08 165.38 2.09C112.92 2.23 68.71 2.49 67.24 2.76C65.78 3.02 61.25 3.96 57.26 4.76Z" />
      <path d="M417.44 59.22C415.58 86.65 414.11 110.75 414.11 112.75C414.11 112.99 414.11 114.24 414.11 116.47L426.9 116.47L439.68 116.47C439.2 112.64 438.93 110.51 438.88 110.08C438.48 106.62 436.35 82.79 434.09 57.22C429.03 -1.77 429.56 1.96 424.77 1.96C421.3 1.96 420.77 2.49 420.77 5.55C420.77 7.69 419.31 31.79 417.44 59.22Z" />
      <path d="M691.61 238.31C698.28 253.85 731.66 331.52 791.74 471.33C794.7 471.25 796.34 471.21 796.67 471.2C805.19 470.93 820.5 465.08 830.89 458.02C842.34 450.3 851.53 439.11 856.46 427.13C856.66 426.61 857.68 424.04 859.52 419.4C859.76 313.94 859.89 255.36 859.92 243.64C860.18 131.25 859.78 64.81 858.99 59.22C854.59 30.72 829.43 8.75 794.94 3.29C781.36 1.16 588.01 1.43 590.01 3.56C590.81 4.49 636.62 110.08 691.61 238.31Z" />
      <path d="M403.46 277.19C403.46 280.26 403.46 280.26 426.76 280.26C439.55 280.26 450.07 279.72 450.07 279.06C450.07 277.06 440.88 160.15 439.95 151.36C439.89 150.81 439.63 148.06 439.15 143.1L426.76 143.1L414.25 143.1L413.58 147.37C406.83 230.72 403.46 274 403.46 277.19Z" />
      <path d="M156.59 402.49C162.85 430.46 186.15 454.02 215.85 462.15C228.49 465.74 254.59 465.34 266.98 461.48C272.17 459.88 277.23 458.28 278.29 458.02C279.49 457.62 282.69 460.28 286.28 464.68C289.61 468.67 292.54 472 292.94 472C294.27 472 316.91 453.09 316.91 452.03C316.91 451.36 314.51 448.03 311.58 444.7C308.65 441.37 306.26 438.31 306.26 437.91C306.26 437.65 308.25 434.72 310.78 431.52C321.84 417.01 326.76 400.63 326.76 378.79C326.76 353.36 319.44 334.72 302.93 318.2C292.01 307.29 279.36 300.1 264.18 296.23C250.6 292.77 229.43 292.77 215.98 296.23C169.91 308.35 145.27 352.29 156.59 402.49ZM276.7 340.71C286.95 352.03 289.48 359.88 289.61 379.46C289.61 393.44 289.21 396.23 286.42 402.36C284.69 406.22 282.82 409.42 282.29 409.42C281.62 409.42 277.36 404.89 272.84 399.43C268.18 393.97 264.05 389.58 263.65 389.71C262.05 390.51 239.81 408.75 239.81 409.42C239.68 409.82 243.54 414.61 248.2 420.07C253.93 426.59 256.32 430.46 255.53 431.39C254.73 432.05 248.07 432.72 240.61 432.72C227.96 432.72 226.36 432.45 218.11 428.32C207.59 423.13 200.93 416.07 195.21 404.22C191.34 395.83 191.08 394.64 191.08 379.46C191.08 359.88 193.74 352.03 203.99 340.71C211.45 332.72 218.11 328.59 228.36 326.06C236.88 323.93 240.21 323.93 249.67 325.66C260.85 327.66 268.84 332.19 276.7 340.71Z" />
      <path d="M362.32 361.21C360.19 371.47 360.45 392.37 362.98 401.83C373.77 443.5 417.44 467.87 462.05 457.09C476.56 453.62 493.21 441.51 502 428.32C502.27 427.92 503.65 425.87 506.12 422.2L492.41 415.28L478.83 408.48C474.83 412.8 472.61 415.2 472.17 415.68C459.65 429.39 445.14 433.52 427.56 428.19C415.31 424.46 404.39 414.74 398.67 402.49C395.21 394.9 394.81 392.77 394.81 380.12C394.81 367.61 395.21 365.34 398.53 358.15C405.46 343.37 417.44 334.05 433.69 330.85C448.47 327.93 461.92 332.85 472.97 345.37C473.35 345.79 475.26 347.93 478.69 351.76L492.41 345.37L506.12 338.98C504.77 336.34 504.01 334.88 503.86 334.58C500.53 328.19 485.09 313.41 477.23 309.28C473.5 307.29 466.98 304.76 462.72 303.56C452.99 301.03 429.69 301.03 419.17 303.69C391.74 310.22 367.78 334.58 362.32 361.21Z" />
      <path d="M574.97 310.48C565.78 315.28 555.26 323.4 555.26 325.79C555.26 326.59 558.72 331.52 562.85 336.71C566.98 341.91 570.57 346.43 570.84 346.7C571.1 347.1 572.97 345.77 574.97 343.77C579.49 339.51 590.01 334.18 598.27 332.05C607.85 329.66 622.77 331.12 629.69 334.98C642.34 342.04 642.21 355.62 629.43 362.41C626.23 364.01 620.64 364.81 608.25 365.21C607.12 365.24 601.44 365.42 591.21 365.74L591.21 380.26L591.21 394.77C599.6 394.77 604.26 394.77 605.19 394.77C630.49 394.77 640.08 399.03 641.41 410.75C642.74 423 632.75 430.99 615.05 431.79C599.73 432.59 584.95 427.66 574.57 418.6C571.9 416.21 569.64 414.61 569.37 414.88C550.6 438.18 550.73 437.78 561.52 445.77C576.43 456.55 592.14 461.35 613.05 461.35C641.41 461.35 661.65 451.76 670.84 434.18C675.63 424.86 675.9 408.88 671.37 400.5C666.18 390.64 657.92 384.38 643.81 379.46C641.28 378.52 641.41 378.39 644.47 377.46C656.32 374 668.57 362.81 671.24 352.96C673.5 344.57 672.04 332.05 668.04 325.13C661.52 313.94 652.99 308.22 636.88 304.09C627.43 301.69 606.39 300.76 598.53 302.36C588.68 304.49 582.82 306.49 574.97 310.48Z" />
    </svg>
  )
}
