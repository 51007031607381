/// <reference path="../all.d.ts" />

import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import "../stylesheets/index.css"
import Navbar from "./Navbar"
import Footer from "./Footer"
import ogImage from "../images/og-image.png"
import appleTouchIcon from "../images/apple-touch-icon.png"
import safariPinnedTab from "../images/safari-pinned-tab.svg"

interface LayoutProps {
  pageTitle: string
  ogImageURL?: string
  pageDescription: string
  pageURL: string
  landingPage?: boolean
}

const title = "QC Cart Craft"
const author = ""

const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  pageDescription,
  pageURL,
  children,
  landingPage,
}) => {
  const calculatedTitle = `${title} - ${pageTitle}`
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{calculatedTitle}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon-32x32.png`}
          sizes="32x32"
        />
        <link rel="mask-icon" href={safariPinnedTab} color="#3f9796" />
        <meta name="theme-color" content="#ef6c00" />
        <meta name="description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageURL} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:title" content={calculatedTitle} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:creator" content={author} />
        <meta property="twitter:title" content={calculatedTitle} />
        <meta property="twitter:description" content={pageDescription} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-EGFJE6QNCZ"></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-EGFJE6QNCZ');`}
        </script>
      </Helmet>
      <noscript>
        JavaScript is currently disabled in your browser. Most features of this
        website require JavaScript to work properly, so please enable JavaScript
        in your browser for full site functionality.
      </noscript>
      <Navbar />
      <main className={`min-h-screen ${landingPage ? "" : "pt-12 sm:pt-16"}`}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
