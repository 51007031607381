import { Link } from "gatsby"
import React from "react"

export default () => {
  return (
    <div className="w-16 sm:w-20 m-auto p-1">
      <Link to="/" className="text-teal-700">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 760 500"
        >
          <path
            d="M73.7 59.66C47.58 85.78 31.72 118.03 25.45 156.95C22.39 176.55 23.72 211.86 28.12 230.92C41.98 289.43 80.76 333.28 136.48 353.41C154.6 359.94 171.26 363.01 194.99 363.81C224.97 364.87 250.56 360.87 274.15 351.54C278.95 349.68 283.22 348.21 283.62 348.21C284.02 348.21 290.42 355.14 297.75 363.54C305.21 372.2 311.61 378.6 312.67 378.2C314.81 377.4 362.25 338.62 362.25 337.55C362.25 337.15 358.92 333.02 354.79 328.22C350.66 323.42 345.19 317.02 342.66 313.96C342.35 313.58 340.8 311.72 338 308.36C341.68 303.88 343.72 301.39 344.13 300.9C370.38 268.64 383.58 226.39 381.85 179.61C380.51 142.03 371.58 113.24 352.12 84.05C337.2 61.66 313.47 40.73 288.95 28.34C278.42 23.01 256.96 15.67 244.3 13.14C235.64 11.41 216.72 9.47 213.51 9.44C210.3 9.41 192.99 9.14 185.66 9.81C141.41 13.28 102.76 30.47 73.7 59.66ZM299.21 141.49C311.07 173.88 309.34 211.33 294.81 242.65C290.82 251.32 290.02 252.52 288.42 250.78C287.35 249.72 278.95 239.85 269.62 228.92C260.29 217.86 252.3 208.8 251.76 208.53C251.23 208.4 240.97 216.4 228.84 226.39C216.84 236.39 205.52 245.72 203.92 247.05C203.7 247.21 202.64 248.01 200.72 249.45C211.59 262.33 217.63 269.48 218.84 270.91C228.84 282.64 236.97 292.77 236.97 293.43C236.97 296.9 208.18 300.23 191.65 298.76C166.86 296.5 148.6 287.97 130.88 270.24C120.22 259.58 117.82 256.38 111.82 244.25C102.09 224.13 99.16 210.93 99.16 186.94C99.29 150.16 109.42 123.63 131.81 101.64C145.94 87.65 165 78.32 185.66 74.98C196.99 73.25 218.58 74.45 230.71 77.52C261.76 85.51 287.75 109.77 299.21 141.49Z"
            fill="#2C7A7B"
          ></path>
          <path
            d="M402.37 123.37C394.77 145.22 392.38 159.49 392.38 186.27C392.38 204.27 392.91 213.33 394.64 222.26C409.17 294.37 459.42 344.61 532.72 360.34C548.98 363.81 582.3 365.14 598.43 363.01C645.88 356.74 683.86 332.75 710.92 292.1C714.12 287.3 717.05 282.5 717.45 281.44C718.11 279.57 709.72 275.17 667.87 255.58C667.06 255.2 663.02 253.29 655.74 249.85C652.78 254.25 651.14 256.69 650.81 257.18C637.35 277.44 618.55 290.9 595.36 296.9C583.5 299.96 561.78 299.7 548.18 296.23C542.32 294.77 531.79 290.77 524.86 287.44C514.33 282.24 510.33 279.17 500.07 269.04C486.87 255.85 480.07 245.19 474.08 228.26C468.48 212.53 467.15 201.6 467.95 180.81C468.75 158.95 471.41 148.02 480.21 130.96C494.07 104.17 514.99 86.98 544.18 78.18C554.31 75.12 558.71 74.59 572.84 74.59C587.23 74.59 590.96 74.98 599.76 77.92C620.42 84.85 639.21 98.84 650.41 115.64C650.77 116.2 652.59 118.99 655.87 124.03C674.35 114.84 684.61 109.73 686.66 108.71C703.59 100.31 717.71 93.11 717.98 92.84C718.91 91.91 706.12 73.39 697.32 62.99C664.54 23.94 615.22 5.28 557.51 9.81C484.34 15.54 425.16 58.86 402.37 123.37Z"
            fill="#4FD1C5"
          ></path>
          <path
            d="M28.52 412.45C27.59 415.79 27.19 428.58 27.46 446.71C27.86 479.49 28.66 482.16 38.52 487.36C43.19 489.89 46.12 490.16 63.84 490.16C81.57 490.16 84.37 489.89 88.5 487.49C95.03 483.49 98.1 477.49 98.9 466.7C98.94 466.09 99.16 463.02 99.56 457.5L93.97 457.5L88.5 457.5C88.5 461.26 88.5 463.35 88.5 463.77C88.5 477.76 81.3 482.16 59.71 481.23C46.38 480.69 41.32 478.56 39.19 472.7C38.39 470.83 37.85 457.64 37.85 443.37C37.85 414.32 38.52 411.25 46.12 408.05C51.85 405.66 77.31 405.52 81.57 408.05C85.3 410.05 88.5 416.98 88.5 422.85C88.5 426.71 88.77 426.85 93.97 426.85C94.34 426.85 96.2 426.85 99.56 426.85C99.08 422.05 98.82 419.38 98.76 418.85C97.96 408.85 94.63 403.26 87.83 399.66C83.44 397.39 79.7 396.86 65.84 396.59C56.65 396.46 46.65 396.99 43.59 397.66C36.52 399.39 30.39 405.39 28.52 412.45Z"
            fill="#234E52"
          ></path>
          <path
            d="M380.52 414.85C378.25 425.38 378.65 466.3 381.05 474.43C383.45 482.43 388.52 487.36 396.78 489.62C405.58 492.02 434.5 490.69 440.9 487.62C447.56 484.29 450.89 477.09 451.03 465.77C451.03 465.21 451.03 462.46 451.03 457.5C448.31 457.5 446.8 457.5 446.49 457.5C442.1 457.5 441.96 457.64 441.16 464.17C439.3 478.16 436.37 480.29 418.24 481.23C404.38 481.89 398.38 480.43 393.58 475.36C390.52 472.03 390.38 471.23 389.98 446.31C389.32 411.25 391.05 407.65 408.64 405.92C430.1 403.92 440.36 408.72 440.36 420.72C440.36 421.13 440.36 423.17 440.36 426.85L445.83 426.85L451.29 426.85C450.97 421.65 450.8 418.76 450.76 418.18C450.23 408.45 447.56 403.79 440.23 399.52C434.23 396.06 404.38 395.13 394.78 398.19C387.05 400.72 382.39 406.06 380.52 414.85Z"
            fill="#234E52"
          ></path>
          <path
            d="M118.36 442.84C109.83 465.9 102.5 485.89 102.23 487.09C101.56 489.09 102.36 489.49 106.76 489.49C107.12 489.49 108.89 489.49 112.09 489.49L116.36 477.49L120.76 465.5L143.81 465.5L166.87 465.5L171.27 477.49L175.53 489.49C178.73 489.49 180.51 489.49 180.87 489.49C185.26 489.49 186.06 489.09 185.4 487.09C184.86 485.22 165.67 433.11 155.14 405.12C154.96 404.62 154.02 402.08 152.34 397.53C147.15 397.53 144.26 397.53 143.68 397.53C137.95 397.53 134.88 398.06 134.48 399.12C134.08 400.06 126.89 419.78 118.36 442.84ZM163.14 456.44C163.14 456.97 154.48 457.5 143.81 457.5C133.15 457.5 124.49 457.1 124.49 456.44C124.49 454.7 140.88 410.19 142.21 408.45C143.01 407.52 144.08 407.26 144.75 407.92C145.41 408.59 149.81 419.52 154.48 432.18C159.27 444.84 163.14 455.77 163.14 456.44Z"
            fill="#234E52"
          ></path>
          <path
            d="M195.13 489.49L200.46 489.49L205.79 489.49L205.79 471.36L205.79 453.24L226.58 453.77L247.37 454.17C249.29 456.41 250.36 457.65 250.57 457.9C253.5 461.37 253.77 462.83 253.77 475.63C253.77 476.55 253.77 481.17 253.77 489.49L259.23 489.49L264.7 489.49L264.17 472.7L263.77 455.9C261.13 453.66 259.66 452.42 259.37 452.17C255.1 448.57 254.97 448.44 257.64 447.51C262.7 445.91 264.43 440.18 264.43 424.45C264.43 411.52 264.03 409.39 261.37 405.39C256.97 398.73 251.77 397.79 221.52 397.66C219.76 397.65 210.96 397.61 195.13 397.53L195.13 443.51L195.13 489.49ZM252.04 411.65C254.17 414.45 254.44 435.11 252.44 438.84C250.3 442.84 243.51 444.17 224.31 444.17C223.08 444.17 216.9 444.17 205.79 444.17C205.79 433.54 205.79 427.63 205.79 426.45C205.79 416.58 206.19 408.19 206.72 407.79C208.05 406.46 238.98 406.72 245.11 408.19C248.04 408.85 251.1 410.45 252.04 411.65Z"
            fill="#234E52"
          ></path>
          <path
            d="M267.1 406.72L281.49 407.12L295.75 407.52L296.15 448.57L296.42 489.49L301.08 489.49L305.75 489.49L306.02 448.44L306.42 407.52L321.48 407.12L336.4 406.72L336.4 402.19L336.4 397.53L301.75 397.53L267.1 397.53L267.1 402.19L267.1 406.72Z"
            fill="#234E52"
          ></path>
          <path 
            d="M465.69 489.49L471.02 489.49L476.35 489.49L476.35 471.5L476.35 453.5C487.15 453.5 493.14 453.5 494.34 453.5C522.2 453.5 524.33 455.24 524.33 478.43C524.33 479.16 524.33 482.85 524.33 489.49L529.66 489.49L534.99 489.49C534.99 481.65 534.99 477.3 534.99 476.43C534.99 460.17 533.39 454.3 528.33 451.37C524.46 448.97 524.46 448.97 527.4 447.64C533.26 444.71 535.13 438.18 534.73 422.85C533.93 399.12 532.86 398.46 494.08 397.79C492.18 397.77 482.72 397.63 465.69 397.39L465.69 443.37L465.69 489.49ZM524.06 427.11C523.66 435.78 523.13 437.91 520.6 440.44C517.8 443.37 516.33 443.51 497.01 443.91C495.63 443.93 488.74 444.07 476.35 444.31L476.35 425.65L476.35 406.86C486.99 406.94 492.89 406.98 494.08 406.99C504.07 406.99 513.67 407.65 516.33 408.59C522.86 410.85 524.46 414.85 524.06 427.11Z"
            fill="#234E52"
          />
          <path 
            d="M545.39 479.23C545.14 479.92 543.9 483.39 541.66 489.62L547.12 489.22L552.59 488.82L556.85 477.49L561.12 466.17L584.17 466.17L607.37 466.17L611.63 477.49L615.9 488.82L621.49 489.22L626.96 489.62C624.72 483.71 623.47 480.42 623.23 479.76C621.23 474.43 613.63 453.64 606.3 433.78C605.41 431.36 600.97 419.28 592.97 397.53L584.31 397.53L575.51 397.53L562.45 433.11C552.5 460.03 546.81 475.41 545.39 479.23ZM602.97 455.64C602.97 457.1 599.1 457.5 584.57 457.5C574.44 457.5 565.78 457.24 565.38 456.84C564.85 456.17 573.25 432.18 581.51 410.45C583.11 406.32 584.97 405.79 586.31 409.12C591.37 421.78 602.97 454.3 602.97 455.64Z"
            fill="#234E52"
          />
          <path 
            d="M636.29 489.49L641.62 489.49L646.95 489.49L646.95 468.16L646.95 446.84L669.61 446.84L692.27 446.84L692.27 442.17L692.27 437.51L669.61 437.51L646.95 437.51L646.95 422.18L646.95 406.86L678.94 406.86L710.92 406.86L710.92 448.17L710.92 489.49L716.26 489.49L721.59 489.49L721.59 448.17L721.59 406.86L736.25 406.86L750.91 406.86L750.91 402.19L750.91 397.53L693.6 397.53L636.29 397.53L636.29 443.51L636.29 489.49Z"
            fill="#234E52"
        />
        </svg>
      </Link>
    </div>
  )
}
