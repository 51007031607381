import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { navItems } from "../constants"
import LayoutContainer from "./LayoutContainer"
import Icon from "./Icon"
import Logo from "./Logo"
import MenuDrawer from "./MenuDrawer"
import { formatPhone } from "../utils"

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      content {
        contactPhone
      }
    }
  `)
  const { contactPhone } = data.content
  return (
    <nav className="fixed w-full top-0 left-0 z-50 bg-white shadow-md h-12 flex items-center sm:h-16">
      <LayoutContainer>
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center">
            <div className="-ml-1"><Logo /></div>
            <div className="pl-6 hidden lg:block">
              <ul className="flex">
                {navItems.map(({ label, to }) => {
                  return (
                    <li key={label} className="pr-6">
                      <Link
                        to={to}
                        className="text-teal-700 hover:text-teal-800 transition-colors duration-150 text-sm"
                      >
                        {label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <a
                href={`tel:+1${contactPhone}`}
                className="flex items-center text-teal-700 hover:text-teal-800 transition-colors duration-150"
              >
                <div>
                  <Icon name="phone" width={26} />
                </div>
                <div className="hidden lg:block pl-1">
                  <span>{formatPhone(contactPhone)}</span>
                </div>
              </a>
            </div>
            <div className="pl-4 block lg:hidden">
              <MenuDrawer />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </nav>
  )
}

export default Navbar
