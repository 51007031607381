import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { formatPhone } from '../utils';
import FooterLogo from './FooterLogo';
import Icon from './Icon';
import LayoutContainer from './LayoutContainer';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            content {
                contactPhone
                contactEmail
            }
        }
    `)
    const { contactEmail, contactPhone } = data.content;
    return (
        <footer className="bg-gray-900 text-gray-400 py-8">
            <LayoutContainer>
                <div className="block justify-between sm:flex">
                    <div>
                        <div className="w-40 mb-4 text-white m-auto sm:m-0"><Link to="/"><FooterLogo /></Link></div>
                    </div>
                    <div>
                        <div className="flex items-center mb-2 justify-center sm:justify-start">
                            <div className="hidden sm:flex w-6 flex-items-center"><Icon name="phone" width={20} /></div>
                            <div><a href={`tel:+1${contactPhone}`}className="text-sm">{formatPhone(contactPhone)}</a></div>
                        </div>
                        <div className="flex items-center mb-2 justify-center sm:justify-start">
                            <div className="hidden sm:flex w-6 flex-items-center"><Icon name="email" width={18} /></div>
                            <div><a className="text-sm" href={`mailto:${contactEmail}`}>{contactEmail}</a></div>
                        </div>
                        <div className="flex items-center justify-center sm:justify-start">
                            <div className="hidden sm:flex w-6 flex-items-center"><Icon name="mapMarker" width={20} /></div>
                            <div><span className="text-sm">Charlotte, NC</span></div>
                        </div>
                    </div>
                </div>
                <p className="mt-4 text-center tracking-widest text-xs sm:text-left">© COPYRIGHT {new Date().getFullYear()} QC CART CRAFT, LLC</p>
            </LayoutContainer>
        </footer>
    )
}