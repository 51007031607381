import React from "react"
import Drawer from '@material-ui/core/Drawer';
import Icon from "./Icon"
import Logo from "./Logo";
import { navItems } from '../constants';
import { Link } from "gatsby";

export default () => {
  const [open, setOpen] = React.useState(false);
  function handleOpen() {
      setOpen(true);
  }
  function handleClose() {
      setOpen(false);
  }
  return (
    <>
      <button className="flex items-center text-teal-700 hover:text-teal-900 transition-colors duration-150" onClick={handleOpen}>
        <Icon name="menuBars" width={26} />
      </button>
     <Drawer onClose={handleClose} open={open} anchor='right'>
        <div className="w-64">
            <div className="h-12 sm:h-16 flex items-center border-b-2 border-gray-300 text-teal-700"><Logo /></div>
            <ul className="text-center mt-8">
                {navItems.map(({ label, to }, index) => {
                    return (
                        <li key={label}>
                            <Link className="text-teal-700 text-lg" to={to}>{label}</Link>
                            {index + 1 !== navItems.length && <div className="m-auto w-12 h-1 bg-teal-300 my-4 rounded"></div>}
                        </li>
                    )
                })}
            </ul>
        </div>
     </Drawer>
    </>
  )
}
