export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const CONTACT_US_ROUTE = "/contact/";
export const ABOUT_US_ROUTE = "/about/";
export const SHOWCASE_ROUTE = "/showcase/";
export const FOR_SALE_ROUTE = "/for-sale/";
export const CONTACT_FORM_ID = 'contact-form';

export const navItems = [
    {
        label: "ABOUT US",
        to: ABOUT_US_ROUTE
    },
    {
        label: "SHOWCASE",
        to: SHOWCASE_ROUTE
    },
    {
        label: "FOR SALE",
        to: FOR_SALE_ROUTE
    },
    {
        label: "CONTACT US",
        to: CONTACT_US_ROUTE
    }
];